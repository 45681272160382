<template></template>
<script>
import { getUserAndTokenByCode, gsmOauthInfo } from "@/api/login";
export default {
  name: "loginIdt",
  data() {
    return {};
  },
  methods: {
    // idt登录完后回到这个页面获取用户信息
    getUserInfo() {
      getUserAndTokenByCode({ code: this.$route.query.code }).then((res) => {
        console.log(res, "axios返回的数据==res");
        if (res.status != 200) {
          this.$message.warning("请求异常1");
          return;
        }
        localStorage.setItem("token_by_code", res.data.token);
        gsmOauthInfo(res.data.code).then((res1) => {
          console.log(res1, "axios返回的数据==res1");
          if (res1.status != 200) {
            this.$message.warning("请求异常2");
            return;
          }
          if (res1.data.code == 0) {
            if (res1.data.token) {
              localStorage.setItem("library_token", res1.data.token);
              localStorage.setItem("loginFailTime", 0);
              localStorage.setItem("readPrivacyPolicy", 0);
            }
            localStorage.setItem(
              "library_userInfo",
              JSON.stringify(res1.data.user)
            );
            this.$store.commit("SET_USERINFO", res1.data.user);
            localStorage.setItem("library_userType", "idt");
            const boforeIdtUrl =
              localStorage.getItem("boforeIdtUrl") + "&idt=" + res.data.code;
            location.replace(boforeIdtUrl);
          }
        });
      });
    },
  },
  created() {
    if (this.$route.query.code) {
      this.getUserInfo();
    }
  },
};
</script>
